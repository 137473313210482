import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Star extends Widget {
  type = 'star';
  gridX = 6
  minGridX = 6

  config = {
    props: [
      'showTitle',
      "alignment",
      "starCount",
      "starRating",
      "fillColor",
      'tooltip',
      'viewOnly',
    ],
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    starCount: {
      displayName: "Star Count",
      type: 'number',
      value: 5
    },
    starRating: {
      displayName: "Star Rating",
      type: 'number',
      value: 0
    },
    fillColor: {
      displayName: "Star Fill Color",
      value: '#333'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can only see the star rating',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  };

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Give a rating"

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }
}

