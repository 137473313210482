import { environment } from "src/environments/environment";
import { Widget } from "./baseWidget";
import { max } from "moment-timezone";

export class Image extends Widget {
  type = 'image';
  gridX = 6
  gridY = 0
  minGridX = 3
  minGridY = 3
  initialPixelHeight = 0
  config = {
    props: [
      "src",
      'tooltip',
      // 'aspectRatio',
      'width',
      'imageAlignment',
      'borderRadius',
    ],
    src: {
      displayName: 'Source URL',
      value: environment.ASSETS_BASE_URL + environment.IMAGE_PLACEHOLDER.PLACEHOLDER_GENERAL,
      resetValue: environment.ASSETS_BASE_URL + environment.IMAGE_PLACEHOLDER.PLACEHOLDER_GENERAL,
      type: 'image-picker'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    borderRadius: {
      displayName: 'Rounded corners',
      value: "0",
      type: 'number',
      max: 50,
      suffix: "%"
    },
    // aspectRatio: {
    //   displayName: 'Aspect ratio',
    //   value: "",
    //   type: 'text'
    // },
    width: {
      displayName: "Width",
      value: "100%",
      type: "select"
    },
    imageAlignment: {
      displayName: "Alignment",
      value: "center",
      type: "select"
    },
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.src.value"
    }
    return map;
  }
}
