import { Widget } from "./baseWidget";
import { AvailableOptions } from './optionsConfig'
import { TextFormatting } from "./textFormat";

interface chip{
  focussed: boolean,
  text: string,
}

export class Chips extends Widget{
  type = 'chips';
  gridX = 12
  minGridX = 3

  config = {
    props: [
      'placeholder',
      'viewOnly',
      'csvMode',
      'alignment',
      'availableOptions',
      'tooltip',
    ],
    availableOptions: JSON.parse(JSON.stringify(AvailableOptions)),
    placeholder: {
      displayName: 'Input Placeholder',
      value: 'Type and press enter to add...',
      type: 'text'
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, existing data will be shown but user can not add more chips',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    csvMode: {
      displayName: 'CSV mode',
      description: 'If enabled, chips input can show csv type data, and it will emit user inputs in csv format',
      value: false,
      type: 'boolean',
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
  };

  //text formatting properties
   textFormat;

  constructor(id: number, name: string){
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Add chips"

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.availableOptions.staticOptions",
      effect: {
        styles: []
      }
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    let array = [];
    if(Array.isArray(values)){
      values.forEach(e => {
        array.push({
          name: e,
          value: e,
          default: false
        })
      })
    } else if(values.length && typeof values == 'string') {
      console.log("[CHIPS] setOptions() this", this)
      if(this.config.csvMode.value){
        console.log("csv mode true")
        values.split(',')?.forEach(val => {
          array.push({
            name: val,
            value: val,
            default: false
          })
        })
      } else{
        let map = {
          name: values,
          value: values,
          default: false
        };
        array = [map];
      }
    }
    console.log("array prepared", array)
    return array;
  }
}
