
<spinner *ngIf="spinner" [selfSpin]="true" [center]="true" [type]="'ball-atom'"></spinner>

<mat-toolbar color="" class="navbar" *ngIf="!deviceInfo?.isSmallSize && !hide">

  <!-- <div>{{ isForm ? "FORM BUILDER" : "BLOOM BUILDER" }}</div> -->
  <!-- <div>{{"isFlow --" + isFlow }}</div>
  <div>{{"isForm --" + isForm }}</div> -->
  <!-- <ng-container></ng-container> -->
  <!-- <ng-container *ngIf="parentType === 'flow'"><div>FLOW BUILDER</div></ng-container>
    <ng-container *ngIf="parentType === 'bloom'"><div>BLOOM BUILDER</div></ng-container> -->

  <div>{{ builderHeadingMap[parentType] }}</div>

  <div *ngIf="flow && parentType === 'flow'">
    <div (click)="onFlowNameClick()" style="cursor: pointer;" matTooltip="Change name of Flow">
      {{flow.name}}
    </div>
  </div>

  <div class="center" *ngIf="parentType !== 'flow'">

    <div *ngIf="parentType === 'bloom' || parentType === 'form'" class="bloom-logo" fxLayout="row" fxLayoutAlign="space-between center">
      <div style="font-size: .6rem;" fxLayout="column" fxLayoutAlign="center center">
        <img class="logo-image" src="{{currentBloomMeta?.logoUrl || formMeta?.logoUrl || localLogo || ''}}" (click)="fileInput.click()" [matTooltip]="parentType === 'bloom' ? 'change bloom logo' : 'Change Form logo'" style="cursor: pointer;">
      </div>
      <input #fileInput style="display: none;" type="file" accept="image/*" (change)="changeDetected($event)">
    </div>

    <div>
      <div
        *ngIf="!textEditMode"
        fxLayout fxLayoutAlign="center center"
        (mouseenter)="showNameEditIcon = true"
        (mouseleave)="showNameEditIcon = false"
      >
        <span id="bloomname" *ngIf="parentType === 'form'">{{formMeta.name}}</span>
        <span id="bloomname" *ngIf="parentType === 'bloom'">{{currentBloomMeta.name}}</span>

        <span
          id="editIcon"
          fxLayout fxLayoutAlign="center center"
          [style.visibility]="showNameEditIcon ? 'visible' : 'hidden'"
          style="opacity: 50%;"
        >
          <mat-icon (click)="onBloomNameEdit()" class="editIcon" matTooltip="change bloom name" style="cursor: pointer;">edit</mat-icon>
        </span>
      </div>

      <div class="center textEditMode" fxLayout="row" *ngIf="textEditMode">
        <ng-container *ngIf="(parentType === 'form'); else elseTemplate">
          <input #bloomNameInput matInput class="bloomname" [(ngModel)]="formMeta.name" (focusout)="editConfirm()">
        </ng-container>
        <ng-template #elseTemplate>
          <input #bloomNameInput matInput class="bloomname" [(ngModel)]="currentBloomMeta.name" (focusout)="editConfirm()">
        </ng-template>

        <button mat-icon-button (click)="editConfirm()" style="cursor: pointer;"><mat-icon class="editConfirmButton">done</mat-icon></button>
      </div>
    </div>
  </div>


  <div class="nav-links"  fxLayout fxLayoutAlign="flex-end center" fxLayoutGap=".2rem">
    <button mat-flat-button class="smallButton saveButton" [disabled]="disabledSave" (click)="onSave()" [ngStyle]="{'border': !disabledSave ? '1px solid green' : 'none'}">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" *ngIf="disabledSave" style="margin-right: .3em; color: #444">cloud_done</mat-icon></span>
        <span>
          <mat-icon
            class="smallIcon"
            fxLayout="row" fxLayoutAlign="center center"
            *ngIf="!disabledSave"
            style="margin-right: .3em;"
            [ngStyle]="{'color': (!disabledSave && !uploadingFailed) ? 'green' : (uploadingFailed ? 'red' : '#444')}"
            [ngClass]="{'uploading': uploading}"
          >
            sync
          </mat-icon></span>
        <span [ngStyle]="{'color': (!disabledSave && !uploadingFailed) ? 'green' : '#444'}">SAVE</span>
      </div>
    </button>
    <button *ngIf="parentType === 'bloom' || parentType === 'form'" mat-flat-button color="primary" class="smallButton" (click)="onPreview()" [disabled]="disablePreviewButton">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">preview</mat-icon></span>
        <span>PREVIEW</span>
      </div>
    </button>
    <button mat-flat-button
    [disabled]="parentType === 'bloom' && !resourcePermission.hasAccess(['bloom.publish'])"
    color="primary" class="smallButton" (click)="openPublishConfig()">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">publish</mat-icon></span>
        <span>PUBLISH</span>
      </div>
    </button>

    <button *ngIf="parentType !== 'form'" mat-flat-button color="primary" class="smallButton" (click)="onShare()">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">share</mat-icon></span>
        <span>SHARE</span>
      </div>
    </button>

  </div>


</mat-toolbar>

