import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";
import { AvailableOptions } from './optionsConfig'
import { WidgetUtilityService } from "../../services/widget-utility.service";


export class Tags extends Widget {
  type = 'tags';
  gridX = 12
  minGridX = 3
  config = {
    props: [
      'availableOptions',
      'tagType',
      'tagColorType',
      'alignment',
      'tooltip'
    ],
    availableOptions: JSON.parse(JSON.stringify(AvailableOptions)),
    tagType: {
      displayName: "Tag Type",
      value: "Raised",
      type: "select"
    },
    tagColorType: {
      displayName: "Tag Color Type",
      value: "primary",
      type: "select"
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config.availableOptions.staticOptions = []
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

  }

  public getMappingConfig(widget?:any) {
    if(widget && widget?.type != "tags") return null;
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.availableOptions.staticOptions",
      effect: {
        styles: ["background-color"],
        "background-color": {
          id: "background-color",
          name: "Background Color",
          inputType: "color-palette"
        }
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }

  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    console.log("set option values:", values)
    let obj = {
      name: '',
      value: '',
      default: false,
    }

    let array = [];
    if(WidgetUtilityService.isIterable(values)){
      values = this.toArray(values)

      for (let key in values) {
        let map = JSON.parse(JSON.stringify(obj));
        map.name = values[key];
        map.value = values[key];
        array.push(map)
      }
    } else if (typeof values == 'string') {
      let parts = values.split(',')
      if(parts.length > 0) {  // for csv support
        console.log("csv true")
        parts.forEach(val => {
          array.push({
            name: val,
            value: val,
            default: false
          })
        })
      } else {
        let map = obj;
        map.name = values;
        map.value = values;
        array = [map];
      }
      this.config.availableOptions.staticOptions = array
    }
    return array;
  }

  toArray(value){
     // If the value is already iterable (like an array or a Set), convert to an array
    if (WidgetUtilityService.isIterable(value)) {
      return Array.from(value);
    }

    // If the value is an object with consecutive integer keys, convert it to an array
    if (typeof value === 'object' && value !== null) {
      return Object.keys(value)
        .map(Number)
        .sort((a, b) => a - b)
        .map(key => value[key]);
    }

    return [];
  }

}
