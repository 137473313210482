<div
  class="listCover"
  [ngStyle]="{'margin-left': (margin?.left || 0) + '%', 'margin-right': (margin?.right || 0) + '%', width: (listPanelSize * 100) + '%'}"
>
  <div
    *ngIf="isLoadingMore || (panelMetaLocal && !runSpinner)"
    id="element" style="background-color: transparent;"
  >
    <div fxLayout="column" fxLayoutAlign="space-between center" style="margin: 0 0 .5rem 0">

      <div *ngIf="!panelMetaLocal.hideTitle" style="width: 100%" fxLayout fxLayoutAlign="center center">
        <div class="list-panel-title">{{ panelMetaLocal?.listPanelTitle || "List Panel" }}</div>
      </div>
      <div style="height: .3rem;"></div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=".5rem" class="actionWrapper">
        <div *ngIf="viewCount > 1 && readyTableData?.length">
          <mat-button-toggle-group class="viewToggleGroup" (change)="changeView($event)">
            <mat-button-toggle
              value="table"
              [checked]="getCurrentViewType() === 'table'"
              matTooltip="Table View"
              *ngIf="panelMetaLocal.viewTypes.table.userCanChoose"
            >
              <mat-icon class="listActionIcon viewToggle">list</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle
              value="card"
              [checked]="getCurrentViewType() === 'card'"
              matTooltip="Card View"
              *ngIf="panelMetaLocal.viewTypes.card.userCanChoose"
            >
              <mat-icon class="listActionIcon viewToggle">grid_on</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle
              value="board"
              [checked]="getCurrentViewType() === 'board'"
              matTooltip="Board View"
              *ngIf="panelMetaLocal.viewTypes.board.userCanChoose"
            >
              <mat-icon class="listActionIcon viewToggle">view_kanban</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="panelMetaLocal.userFilterEnabled || panelMetaLocal.userSortEnabled || (panelMetaLocal.viewTypes?.canSelectFields)">
          <!-- style="height: 2rem; align-items: center;" -->
          <mat-button-toggle-group>
            <mat-button-toggle [hidden]="!panelMetaLocal.userFilterEnabled" class="actionToggleButton" matTooltip="Filter" [checked]="false">
              <mat-icon class="listActionIcon" #filterMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">filter_alt</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [hidden]="!panelMetaLocal.userSortEnabled" class="actionToggleButton" matTooltip="Sort" [checked]="false">
              <mat-icon class="listActionIcon" #sortMenu="matMenuTrigger" [matMenuTriggerFor]="menu3">sort</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [hidden]="!panelMetaLocal.viewTypes?.canSelectFields" class="actionToggleButton" matTooltip="Choose fields" [checked]="false">
              <mat-icon class="listActionIcon" [matMenuTriggerFor]="menu4">checklist</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div *ngIf="builderMode">
          <div fxLayout fxLayoutAlign="end end" class="actionWrapper">
            <button mat-icon-button (click)="openSettings()"  matTooltip="Settings"><mat-icon>settings</mat-icon></button>
          </div>
        </div>
        <button mat-icon-button (click)="refreshPanel()" *ngIf="!isInputData" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>

        <mat-menu style="border: 1px solid black;" #menu2="matMenu" xPosition="before">
          <div *ngIf="panelMetaLocal.userFilterEnabled" (click)="$event.stopPropagation()" style="min-width: 25rem; max-width: 50rem" ngStyle.lt-md="{'min-width: '20rem', 'max-width': '40rem'}">
            <!-- <app-filter-config
              [filter]="{ filterEnabled: true, filterItems: externalFilters }"
              [boxObjectAttributes]="panelMetaLocal.listAttributes"
              [enableSecurity]="false"
              [supportApplyButton]="true"
              [hideToggle]="true"
              [lockFilterType]="true"
              [boxObjectFunction]="panelMetaLocal.getFn"
              (selectedFilter)="applyUserFilters($event)"
              (cancelFilter)="cancelFilter()"
            >
            </app-filter-config> -->
            <app-filter-component
            [filter]="{ filterEnabled: true, filterItems: externalFilters }"
            [boxObjectAttributes]="panelMetaLocal.listAttributes"
            [enableSecurity]="false"
            [supportApplyButton]="true"
            [hideToggle]="true"
            [lockFilterType]="true"
            [boxObjectFunction]="panelMetaLocal.getFn"
            (selectedFilter)="applyUserFilters($event)"
            (cancelFilter)="cancelFilter()"
            >
            </app-filter-component>
          </div>
        </mat-menu>

        <mat-menu style="border: 1px solid black;" #menu3="matMenu" xPosition="before">
          <div (click)="$event.stopPropagation()" style="min-width: 25rem; max-width: 50rem" ngStyle.lt-md="{'min-width: '20rem', 'max-width': '40rem'}">
            <app-sort-config
              [sort]="{ sortEnabled: true, sortAttributes: externalSort }"
              [boxObjectAttributes]="this.panelMetaLocal.listAttributes"
              [enableSecurity]="false"
              [supportApplyButton]="true"
              [hideToggle]="true"
              (selectedSort)="applyUserSort($event)"
              (cancelSort)="cancelSort()"
            >
            </app-sort-config>
          </div>
        </mat-menu>

        <mat-menu style="border: 1px solid black;" #menu4="matMenu" xPosition="before">
          <app-attribute-selection
            [boxObjectAttributes]="panelMeta.listAttributes|| []"
            [listAttributes]="panelMetaLocal.listAttributes || []"
            [supportApplyButton]="true"
            [allowReorder]="true"
            [emitAtInit]="false"
            (listAttributeSelected)="columnSelectionChanged($event)"
          ></app-attribute-selection>
        </mat-menu>
      </div>
    </div>

    <div *ngIf="panelMetaLocal.summary?.enabled && panelMetaLocal.summary?.charts?.length" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <div *ngFor="let chart of panelMetaLocal.summary.charts" fxFlex="30">
        <app-widget-builder
          [widgetMeta]="chart"
          [panelMeta]="panelMeta"
          [builderMode]="false"
          (newWidgetMeta)="raiseNewWidgetMeta($event)"
          style="width: 100%; height: 100%;"
        >
        </app-widget-builder>
      </div>
    </div>

    <div style="margin: 0 0 .5rem 0;">
      <app-data-table
        [panelMeta]="panelMetaLocal"
        [processedData]="readyTableData"
        [displayedColumns]="displayedColumns"
        [totalCount]="dataLength"
        [pageSize]="page.size"
        [pageNumber]="page.number"
        [error]="dataLoadError"
        [builderMode]="builderMode"
        [viewType]="currentViewType"
        [rawBoxData]="rawBoxData"
        [availableAttributes]="availableAttributes"
        [isInputData]="isInputData"
        (raisePageEvent)="pageEvent($event)"
        (metaChange)="panelMetaChange($event)"
        (subPanel)="createSubListPanel($event)"
        (columnSelectionChanged)="columnSelectionChanged($event)"
        (customOrderChanged)="customOrderChanged($event)"
        (selectedRowData)="selectedRowData($event)"
        (checkboxSelected)="selectedCheckboxData($event)"
        (rowDeletion)="deleteResource($event)"
      >
      </app-data-table>
      <!-- [loadingData]="loadingData" -->
      <!-- [runSpinner]="runSpinner" -->
    </div>

    <!-- [length]="dataLength" -->
    <!-- [pageIndex]="0" -->

    <div *ngIf="showSinglePagePagination && !isLoadingMore" fxLayout="row" fxLayoutAlign="center center">
      <button mat-button color="primary" (click)="loadMoreData()">show more</button>
    </div>
  </div>

  <div *ngIf="runSpinner && !pageService.isPrerendered" class="spinnerWrap" style="width: 100%;">
    <spinner [type]="'skeleton'" style="width: 100%;" [selfSpin]="true" [skeletonPresetCode]="'list-table-view-loader'"></spinner>
  </div>

  <mat-paginator #paginator
    *ngIf="showPagination"
    [disabled]="runSpinner"
    [length]="dataLength"
    [pageSize]="page.size"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent($event)"
    aria-label="Select page">
  </mat-paginator>

</div>

