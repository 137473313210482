<div *ngIf="clientPlatformService.clientType === 'GOOGLE-ADDON'" class="success-page">
    <p class="success-message" *ngIf="isNew">
      Congratulations! Your connection was successfully created. Please close this window and continue with the client platform.
    </p>
    <p class="success-message" *ngIf="!isNew">
      Your connection has been updated successfully. Please close this window and continue with the client platform.
    </p>
    <p class="note">
      Note: In Client Platform, click on refresh to check for new connections.
    </p>
  </div>
  

<div *ngIf="clientPlatformService.clientType !== 'GOOGLE-ADDON'" class="redirecting">
    <p class="redirecting-message">Redirecting...</p>
</div>
