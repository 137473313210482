<spinner *ngIf="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div *ngIf="onlyDisplay" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="column" fxFlex="5">
    <mat-icon color="primary" matPrefix>storage</mat-icon>
  </div>

  <div fxLayout="column" fxFlex="5" *ngIf="box.logo">
    <img [src]="box.logo" class="image-size" />
  </div>

  <div fxLayout="column" fxFlex="80">
    {{ box.name }}
  </div>

  <div fxLayout="column" fxFlex="10"></div>
</div>

<div *ngIf="!onlyDisplay" class="input-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field class="form-field" fxFlex="80%" fxFlex.lt-sm="100%">
    <mat-icon color="primary" matPrefix>storage</mat-icon>
    <mat-label>
      <span>Choose {{boxType ? boxType : 'Application'}}</span>
    </mat-label>

    <div class="input-row" fxLayout="row" fxLayoutAlign="start center">
      <input #appinput matInput [formControl]="appListingACFrom" (focus)="setFilterOptions()"
        [matAutocomplete]="auto" />
      <img *ngIf="appListingACFrom.value && box.logo" [src]="box.logo" class="image-size" />
    </div>

    <button *ngIf="appListingACFrom.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection($event)"
      matTooltip="Clear selected {{boxType ? boxType : 'Application'}}" fxLayout="row" fxLayoutAlign="end center"
      class="clear-icon-button">
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunction"
      (optionSelected)="appSelected($event.option.value)" class="autocomplete">

      <mat-option [value]="starchMap" *ngIf="supportStarch" class="autocomplete-option" fxLayout="row" fxLayoutAlign="center center">
        <div class="option-content" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="option-icon" color="accent">dataset</mat-icon>
          <span class="option-text">Starch</span>
        </div>
      </mat-option>

      <mat-divider></mat-divider>

      <mat-option *ngFor="let option of appListingOptions | async" [value]="option" class="autocomplete-option" fxLayout="row" fxLayoutAlign="center center">
        <div class="option-content" fxLayout="row" fxLayoutAlign="center center">
          <img [src]="option.logo" alt="{{ option.name }}" class="image-size" />
          <span class="option-text">{{ option.name }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="button-container" fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button color="primary" (click)="appsPopup()"
      matTooltip="Browse and choose {{boxType ? boxType : 'Application'}}" aria-label="View Available Apps">
      <mat-icon>dashboard</mat-icon>
    </button>
  </div>

  <mat-spinner *ngIf="spinner" color="warn" diameter="15" class="responsive-spinner" fxFlex="0 0 auto"></mat-spinner>
</div>
