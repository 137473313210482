import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { DateTimePickerModule } from 'src/app/shared/datetimepicker/datetimepicker.module';
import { DragnDropFileModule } from 'src/app/shared/dropfile/dragndropfile.module';
import { DurationPickerModule } from 'src/app/shared/duration-picker/duration-picker.module';
import { MccTimerPickerModule } from 'material-community-components/timer-picker';
import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { BasicChartsModule } from 'src/app/shared/basic-charts/basic-charts.module';

import { LinkComponent } from './components/link/link.component';
import { ImageComponent } from './components/image/image.component';
import { LabelComponent } from './components/label/label.component';
import { ButtonComponent } from './components/button/button.component';
import { TextinputComponent } from './components/textinput/textinput.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ChipsComponent } from './components/chips/chips.component';
import { ChoiceComponent } from './components/choice/choice.component';
import { BaseWidgetComponent } from './components/base-widget/base-widget.component';
import { DateTimeComponent } from './components/datetime/datetime.component';
import { EmbedComponent } from './components/embed/embed.component';
import { DateComponent } from './components/date/date.component';
import { TimeComponent } from './components/time/time.component';
import { ImageInputComponent } from './components/imageinput/imageinput.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { CardComponent } from './components/card/card.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { PeriodComponent } from './components/period/period.component';
import { RichTextinputComponent } from './components/richtextinput/richtextinput.component';
import { SelectComponent } from './components/select/select.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { IconComponent } from './components/icon/icon.component';
import { NumberinputComponent } from './components/numberinput/numberinput.component';
import { SliderComponent } from './components/slider/slider.component';
import { StarComponent } from './components/star/star.component';
import { TableComponent } from './components/table/table.component';
import { DurationComponent } from './components/duration/duration.component';
import { WidgetDragHandleComponent } from './widget-drag-handle/widget-drag-handle.component';
import { WidgetWrapperComponent } from '../widget-wrapper/widget-wrapper.component';
import { TagsComponent } from './components/tags/tags.component';
import { ChartComponent } from './components/chart/chart.component';
import { SpaceComponent } from './components/space/space.component';
import { ConnectionComponent } from './components/connection/connection.component';
import { BoxPickerModule } from 'src/app/shared/box-picker/box-picker.module';
import { ConnectionBoxPopupComponent } from './components/connection/connection-popup/connection-box-popup.component';
import { NewConnectionModule } from 'src/app/modules/organization/connection/new-connection/new-connection.module';
import { ConnectionWidgetModule } from './components/connection/connection.module';
import { SnackbarModule } from 'src/app/shared/snackbar/snackbar.module';
import { LoginComponent } from './components/login/login.component';
import { InlineTitleComponent } from './inline-title/inline-title.component';

// import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [
    WidgetDragHandleComponent,
    LinkComponent,
    ImageComponent,
    ButtonComponent,
    TextinputComponent,
    CheckboxComponent,
    LabelComponent,
    ParagraphComponent,
    SelectComponent,
    SeparatorComponent,
    ImageInputComponent,
    CardComponent,
    ChoiceComponent,
    BaseWidgetComponent,
    RichTextinputComponent,
    TimeComponent,
    ChipsComponent,
    AutoCompleteComponent,
    TextareaComponent,
    DateComponent,
    DateTimeComponent,
    PeriodComponent,
    EmbedComponent,
    NumberinputComponent,
    SliderComponent,
    StarComponent,
    TableComponent,
    IconComponent,
    DurationComponent,
    // WidgetWrapperComponent,
    TagsComponent,
    ChartComponent,
    SpaceComponent,
    LoginComponent,
    InlineTitleComponent
    // ConnectionComponent,
    // ConnectionBoxPopupComponent
    // ContextMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    DurationPickerModule,
    MccTimerPickerModule,
    DateTimePickerModule,
    DragnDropFileModule,
    AppSelectionModule,
    QuillModule.forRoot(),
    BasicChartsModule,
    // SnackbarModule
    // ConnectionWidgetModule
    // BoxPickerModule,
    // NewConnectionModule
  ],
  exports: [
    WidgetDragHandleComponent,
    LinkComponent,
    ImageComponent,
    LabelComponent,
    ButtonComponent,
    TextinputComponent,
    CheckboxComponent,
    ParagraphComponent,
    SelectComponent,
    TableComponent,
    SeparatorComponent,
    ImageInputComponent,
    IconComponent,
    CardComponent,
    ChoiceComponent,
    BaseWidgetComponent,
    RichTextinputComponent,
    SliderComponent,
    ChipsComponent,
    AutoCompleteComponent,
    TextareaComponent,
    NumberinputComponent,
    DateComponent,
    DateTimeComponent,
    PeriodComponent,
    TimeComponent,
    StarComponent,
    EmbedComponent,
    DurationComponent,
    // WidgetWrapperComponent,
    TagsComponent,
    ChartComponent,
    SpaceComponent,
    LoginComponent
    // ConnectionComponent,
    // ConnectionBoxPopupComponent
  ]
})
export class WidgetsModule { }
