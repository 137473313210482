<spinner *ngIf="spinner" [center]="true" [selfSpin]="true" size="la-2x" color="grey" type="ball-triangle-path">
</spinner>

<div *ngIf="onlyDisplay && currentSelectedAction" fxLayout="row" fxLayoutAlign="start center">>
  <div fxLayout="column" fxFlex="5">
    <mat-icon color="primary" matPrefix>event</mat-icon>
  </div>
  <div fxLayout="column" fxFlex="5">
  </div>
  <div fxLayout="column" fxFlex="80">
    {{currentSelectedAction?.name}}
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>

<div *ngIf="display && !onlyDisplay && !config?.hideSelection" class="input-container" fxLayout="row"
  fxLayoutAlign="start center">
  <mat-form-field class="form-field" fxFlex="80%" fxFlex.lt-sm="100%">
    <mat-icon color="primary" matPrefix>event</mat-icon>
    <mat-label>
      <span>Choose {{actionType ? actionType : config.isListOnlyObject ? 'Object' : 'Action'}}</span>
    </mat-label>

    <div class="input-row" fxLayout="row" fxLayoutAlign="start center">
      <input matInput #actionInput [formControl]="actionListingACFrom" (focus)="setStepFilterOptions()"
        [matAutocomplete]="autoBoxEvent" />
    </div>

    <button *ngIf="actionListingACFrom.value" matTooltip="Clear selected {{actionType ? actionType : 'Action'}}"
      matSuffix mat-icon-button aria-label="Clear"
      (click)="actionListingACFrom.patchValue('');$event.stopPropagation();selectedAction.emit(null)"
      class="clear-icon-button">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #autoBoxEvent="matAutocomplete" [displayWith]="displayFunction"
      (optionSelected)="actionSelected($event.option.value);" class="autocomplete">
      <mat-option *ngFor="let option of actionListingOptions | async" [value]="option" class="autocomplete-option" fxLayout="row" fxLayoutAlign="center center">
        <div class="option-content" fxLayout="row" fxLayoutAlign="center center">
          <span class="option-text"> {{ option.name }} </span>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="actionListingACFrom?.errors?.errorMsg">
      {{ actionListingACFrom.errors.errorMsg }}
    </mat-error>
  </mat-form-field>
  <mat-spinner *ngIf="spinner" color="warn" [diameter]="20"></mat-spinner>
</div>

<div *ngIf="isOptionsToCollect" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
  <ng-container *ngFor="let option of attributeOptions; let i = index">
    <mat-form-field>
      <mat-label>{{ option.name }}</mat-label>
      <input matInput (input)="attributeOptionChanged($event, i)" [required]="option.required"
        [(ngModel)]="option.value">
      <mat-hint style="color: #3f51b5;">Type: {{ option.dataType }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <button mat-stroked-button color="primary" (click)="getAttributes()"><mat-icon>east</mat-icon></button>
</div>