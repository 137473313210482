<div>
  <div (cdkDropListDropped)="drop($event)" cdkDropList>
    <div *ngFor="let page of form?.sections;let i = index">
      <div cdkDrag class="card1" [ngClass]="{'selected': selectedPage === page && !isSuccessPageSelected}" cdkDropList>
        <div style="padding: 10px 8px 10px 8px;" fxLayout="row"
       fxLayoutAlign="center center" >

          <div fxLayoutAlign="end end" fxLayout="column" fxFlex="10"
            *ngIf="['main', 'introduction'].includes(page.code)">
          </div>
          <div fxLayoutAlign="start start" fxLayout="column" fxFlex="10"
            *ngIf="!['main', 'introduction'].includes(page.code)" cdkDragHandle>
            <mat-icon style="color: rgb(183, 183, 183);" matTooltip="Drag and move section" >drag_indicator</mat-icon>
          </div>
          <div fxLayoutAlign="start start" fxLayout="column" fxFlex="70" (click)="setPage(page)">
             {{page.name}}
          </div>
          <div fxLayoutAlign="end end" fxLayout="column" fxFlex="20"
          *ngIf="!['main'].includes(page.code)">
              <mat-icon matTooltip="Delete section" color="warn" (click)="removePage(i)">delete</mat-icon>
          </div>
          <div fxLayoutAlign="end end" fxLayout="column" fxFlex="20"
          *ngIf="['main'].includes(page.code)">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="card1" (click)="gotoThankYou()" [ngClass]="{'selected': isSuccessPageSelected}">
      <div style="padding: 10px 8px 10px 8px;">
        <div fxLayout="column" fxFlex="10">
          </div>
        <div fxLayoutAlign="start start" fxLayout="column" fxFlex="90"> Success page </div>
      </div>
    </div>
  </div>
</div>

<div style="height: 10px;"></div>
<div fxLayout="row"  fxLayoutAlign="center center">
  <button matTooltip="Add new section" mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon> Section
  </button>
</div>

<!-- Menu for new page creation -->
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="addIntroduction()"
  *ngIf="!form?.sections || form?.sections?.length == 0 || form.sections?.[0]?.code != 'introduction'">Introduction</button>
  <button mat-menu-item (click)="createNewSection()">Section</button>
</mat-menu>


