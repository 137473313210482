<spinner [selfSpin]="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div *ngIf="form?.theme?.themes?.length && isThemesReady">
  <div>


    <div *ngFor="let theme of form?.theme?.themes;let i = index; trackBy: trackById">

      <div class="card1" (click)="setTheme(theme, i)" [ngClass]="{'selected': form?.theme?.selectedTheme?.code === theme?.code}">
        <div style="padding: 5px;">
        <div fxLayout="row" fxLayoutAlign="start start">
          <div fxLayout="column" fxFlex="8" *ngIf="form?.theme?.selectedTheme?.code === theme?.code" matTooltip="Active theme applied to the form">
            <mat-icon class="selectedIcon">check_circle</mat-icon>
          </div>
          <div fxLayout="column" fxFlex="8" *ngIf="form?.theme?.selectedTheme?.code != theme?.code">
          </div>
          <div fxLayout="column" fxFlex="2"></div>
          <div fxLayout="column" fxFlex="17">
              <div class="displayBox" [fxLayoutAlign]="theme?.alignment ? theme?.alignment + ' start' : 'center start'"
              [ngStyle]="theme?.style ? theme.style : {'background-color': '#f5f5f5'}">
                 <mat-card class="innerCard" [ngStyle]="getCombinedFramedStyles(theme)"></mat-card>
              </div>
          </div>
          <div fxLayout="column" fxFlex="3">
          </div>
          <div fxLayout="column" fxFlex="55">
            {{theme.name}}
          </div>
          <div fxLayout="column" fxFlex="5">
            <mat-icon matTooltip="Edit Theme" (click)="editTheme(theme, i)">edit</mat-icon>
          </div>
          <div fxLayout="column" fxFlex="5"></div>
          <div fxLayout="column" fxFlex="5">
            <mat-icon color="warn" matTooltip="Delete Theme" (click)="deleteTheme(theme, i)">delete</mat-icon>
              <!-- <mat-icon style="cursor: pointer;" [matMenuTriggerFor]="actionmenu" matTooltip="More Action">more_vert</mat-icon> -->
          </div>
        </div>
        </div>
      </div>

      <div *ngIf="editIndex == i && newThemeMap && this.configureType == 'edit'">
        <ng-template [ngTemplateOutlet]="editThemeSection"></ng-template>
      </div>

      <!-- <mat-menu #actionmenu="matMenu">
       <div>
        <button mat-button matTooltip="Edit Theme" (click)="editTheme(theme, i)">
          Edit <mat-icon >edit</mat-icon>
        </button>
       </div>
        <button mat-button color="warn" matTooltip="Delete Theme" (click)="deleteTheme(theme, i)">
          Remove <mat-icon >delete</mat-icon>
        </button>
      </mat-menu> -->
    </div>
  </div>
</div>

<div *ngIf="newThemeMap && !this.configureType">
  <ng-template [ngTemplateOutlet]="editThemeSection"></ng-template>
</div>
<mat-divider *ngIf="newThemeMap"></mat-divider>

<div style="height: 10px;"></div>
<div fxLayout="row"  fxLayoutAlign="center center" *ngIf="!this.newThemeMap">
  <button matTooltip="Add new Theme" mat-stroked-button color="primary" (click)="createNewTheme()">
    <mat-icon>add</mat-icon> Theme
  </button>
</div>


<ng-template #editThemeSection>
  <div style="height: 6px"></div>
  <div fxLayout="row" style="font-weight: 600;" matTooltip="Click to rename" fxLayoutAlign="center center" *ngIf="newThemeMap && !isNameEditMode" (click)="toggleEditMode()">
    Configure {{newThemeMap.name}}
  </div>
  <div fxLayout="row" style="font-weight: 600;" fxLayoutAlign="center center" *ngIf="newThemeMap && isNameEditMode" (click)="toggleEditMode()">
    <input [(ngModel)]="newThemeMap.name" (click)="$event.stopPropagation()" (blur)="toggleEditMode()" />
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap">
    <mat-card  fxLayout="column"
     style="padding-left: 2px;padding-right: 2px;width: 90%;">
     <mat-card-content>
      <div fxLayout="row"  fxLayoutAlign="start center">
        <span  fxLayoutAlign="start center">
          Background: &nbsp;
          <input #colorInput class="colorInput"
           matInput type="color" name="color" [value]="newThemeMap?.['background-color']"
          (input)="colorChanged($event.target)">
          <button matTooltip="Reset color" mat-icon-button aria-label="Clear"
            (click)="backgroundColorReset()">
              <mat-icon class="resetIcon">close</mat-icon>
        </button>
        </span>
      </div>
      <div style="height: 2px"></div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <!-- <div fxLayout="column" > -->
          Background Image: &nbsp;
        <!-- </div> -->
        <div appearance="standard" (click)="openBackgroundPanel = !openBackgroundPanel"
           class="colorInputWrap"  fxLayout="row">
           <mat-icon class="formatIcon" fontIcon="imagesmode" matTooltip="Background image"></mat-icon>
          <mat-icon *ngIf="newThemeMap?.image?.url?.length"  style="font-size: .9rem; color: green;" fxLayoutAlign="center center" fontIcon="done"></mat-icon>
        </div>
        <button *ngIf="newThemeMap?.image?.url?.length" matTooltip="Reset image" mat-icon-button aria-label="Clear"
            (click)="backgroundImageReset()">
              <mat-icon class="resetIcon">close</mat-icon>
        </button>
      </div>
      <div style="height: 2px"></div>
      <div fxLayout="row" *ngIf="openBackgroundPanel">
        <div fxLayout="column" fxFlex="80">
          <mat-form-field appearance="outline">
            <mat-label>
              Image URL
            </mat-label>
            <input matInput
            [(ngModel)]="newThemeMap.image.url" (change)="customPropertyChanged($event.target)">
            <button *ngIf="newThemeMap?.image?.url?.length" matSuffix mat-icon-button aria-label="Clear"
            (click)="backgroundImageReset()">
              <mat-icon>close</mat-icon></button>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="20">
          <dropzone type="iconbutton" [noPreview]="true" (fileChange)="imageSelectionChange($event)"></dropzone>
        </div>
      </div>
      <div fxLayout="row">
        Opacity: &nbsp;
        <mat-slider style="min-width: 70px;margin-top: -12px;" min="1" max="100" showTickMarks discrete [displayWith]="formatLabel">
          <input matSliderThumb [(ngModel)]="newThemeMap.opacity" (input)="opacityChanged()">
        </mat-slider>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        Button Type:
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div>
          <mat-form-field class="dropdown thin-outline-field" flex="95%" appearance="outline"
            (click)="$event.stopPropagation();$event.preventDefault();">
            <!-- <mat-label>Button color type</mat-label> -->
            <mat-select [value]="newThemeMap['button-type']"
              (selectionChange)="buttonTypeChanged($event)">
              <mat-option [value]="type" *ngFor="let type of buttonTypeOptions">
                {{ type | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="start center">
        Button Color Type:
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <div>
          <mat-form-field class="dropdown thin-outline-field" flex="95%" appearance="outline"
            (click)="$event.stopPropagation();$event.preventDefault();">
            <!-- <mat-label>Button color type</mat-label> -->
            <mat-select [value]="newThemeMap['button-color-type'].value"
              (selectionChange)="buttonColorTypeChanged($event)">
              <mat-option [value]="type" *ngFor="let type of buttonColorTypeOptions">
                {{ type | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        @if (newThemeMap['button-color-type'].value === "custom") {
          <div class="colorInputWrap">
            <mat-icon class="colorIcon" matTooltip="Button color">format_color_fill</mat-icon>
            <div
              class="colorInput" name="btnColor"
              ngx-colors-trigger
              (input)="buttonColorChanged($event)"
              [style.background-color]="newThemeMap['button-color-type'].color || '#f5f5f5'"
              [ngModel]="newThemeMap['button-color-type'].color || '#f5f5f5'"
              [colorsAnimation]="'popup'"
              [acceptLabel]="'CONFIRM'"
              [palette]="pageService.recentlyUsedColorPalette"
            ></div>

            <div class="reset-color" matTooltip="Reset button color" (click)="buttonColorChanged('#f5f5f5')"></div>
          </div>
        }
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <span  fxLayoutAlign="start center">
          Button Text: &nbsp;
          <div class="colorInputWrap">
          <mat-icon class="colorIcon" matTooltip="Button color">format_color_fill</mat-icon>
          <div
              class="colorInput" name="btnColor"
              ngx-colors-trigger
              (input)="buttonTextColorChanged($event)"
              [style.background-color]="newThemeMap['button-text-color'] || '#f5f5f5'"
              [ngModel]="newThemeMap['button-text-color'] || '#f5f5f5'"
              [colorsAnimation]="'popup'"
              [acceptLabel]="'CONFIRM'"
              [palette]="pageService.recentlyUsedColorPalette"
            >
          </div>
          <div class="reset-color" matTooltip="Reset button color" (click)="buttonTextColorChanged('#f5f5f5')"></div>
          </div>
        </span>
      </div>

    </mat-card-content>
    </mat-card>
  </div>
  <div style="height: 8px"></div>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap">

      <!-- form frame config -->
      <mat-card  fxLayout="column"
      style="padding-left: 2px;padding-right: 2px;width: 90%;">
      <mat-card-content>
       <div fxLayout="row" style="font-weight: bold;">
         Frame Configuration
       </div>
       <div fxLayout="row"  fxLayoutAlign="start center">
         <span  fxLayoutAlign="start center">
            Color: &nbsp;
           <input #colorInput class="colorInput"
            matInput type="color" name="color" [value]="newThemeMap?.frameconfig?.['background-color']"
           (input)="colorChanged($event.target, 'frameconfig')">
           <button matTooltip="Reset color" mat-icon-button aria-label="Clear"
             (click)="backgroundColorReset('frameconfig')">
               <mat-icon class="resetIcon">close</mat-icon>
         </button>
         </span>
       </div>

       <div style="height: 2px"></div>

       <div fxLayout="row">
         Opacity: &nbsp;
         <mat-slider style="min-width: 70px;margin-top: -12px;" min="1" max="100" showTickMarks discrete
         [displayWith]="formatLabel">
           <input matSliderThumb [(ngModel)]="newThemeMap.frameconfig.opacity" (input)="opacityChanged('frameconfig')">
         </mat-slider>
       </div>

       <div fxLayout="column">
        <div class="heading">
          Margin
        </div>
        <div fxLayout fxLayoutAlign="space-around center">
          <div fxLayoutAlign="center center" fxFlex="15">{{ "Left " + newThemeMap.frameconfig.margin?.left + "%" }}</div>
          <mat-slider
            fxFlex="70"
            class="slider hide-start-thumb hide-end-thumb"
            color="primary"
            [displayWith]="displayThumbText.bind(this)"
            [step]="1"
            [showTickMarks]="true"
            [discrete]="true"
            [max]="100"
            [min]="0"
          >
            <input [ngModel]="newThemeMap.frameconfig?.margin?.left" matSliderStartThumb (dragStart)="dragStart($event, 'left')" (valueChange)="marginChange($event, 'left', newThemeMap.frameconfig.margin)">
            <input [ngModel]="newThemeMap.frameconfig?.margin?.right" matSliderEndThumb (dragStart)="dragStart($event, 'right')" (valueChange)="marginChange($event, 'right',  newThemeMap.frameconfig.margin)">
          </mat-slider>
          <div fxLayoutAlign="center center" fxFlex="15">{{ "Right " + (newThemeMap.frameconfig.margin?.right ? (100 - (newThemeMap.frameconfig.margin?.right || 0)) : 0) + "%" }}</div>
        </div>
        <div fxLayout fxLayoutAlign="space-around center">
          <div fxLayoutAlign="center center" fxFlex="15">{{ "Top " + newThemeMap.frameconfig.margin?.top + "%" }}</div>
          <mat-slider
            fxFlex="70"
            class="slider hide-start-thumb hide-end-thumb"
            color="primary"
            [displayWith]="displayThumbText.bind(this)"
            [step]="1"
            [showTickMarks]="true"
            [discrete]="true"
            [max]="100"
            [min]="0"
          >
            <input [ngModel]="newThemeMap.frameconfig?.margin?.top" matSliderStartThumb (dragStart)="dragStart($event, 'top')" (valueChange)="marginChange($event, 'top', newThemeMap.frameconfig.margin)">
            <input [ngModel]="newThemeMap.frameconfig?.margin?.bottom" matSliderEndThumb (dragStart)="dragStart($event, 'bottom')" (valueChange)="marginChange($event, 'bottom',  newThemeMap.frameconfig.margin)">
          </mat-slider>
          <div fxLayoutAlign="center center" fxFlex="15">{{ "Bottom " + (newThemeMap.frameconfig.margin?.bottom ? (100 - (newThemeMap.frameconfig.margin?.bottom || 0)) : 0) + "%" }}</div>
        </div>
      </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap" style="padding-top: 15px;">
    <button matTooltip="Cancel" mat-button color="warn" (click)="clearTheme()">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <div fxFlex="5"></div>
    <button matTooltip="Done" mat-raised-button color="primary" (click)="addNewTheme()">
      <mat-icon>check</mat-icon> Save
    </button>
  </div>

</ng-template>


